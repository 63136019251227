import Layout from "../layouts/base-layout.vue";
import Home from "../views/HomeView.vue";
import ErrorView from "../views/ErrorView.vue";
import SearchView from "../views/SearchView.vue";
import CategoryView from "../views/CategoryView.vue";
import PostView from "../views/PostView.vue";
import ContactView from "../views/ContactView.vue";
import ComingSoonView from "../views/ComingSoonView.vue";
// import AboutUsView from "../views/AboutUsView.vue";
import TermsView from "../views/TermsView.vue";
import PrivacyView from "../views/PrivacyView.vue";
// import SignInView from "../views/SignInView.vue";
// import PublicityView from "../views/PublicityView.vue";
import RegisterView from "../views/RegisterView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/search/:search",
        name: "search",
        component: SearchView,
      },
      {
        path: "/category/:category",
        name: "category",
        component: CategoryView,
      },
      {
        path: "/post/:post",
        name: "post",
        component: PostView,
      },
      {
        path: "/about-us",
        name: "about-us",
        component: ComingSoonView,
      },
      {
        path: "/terms",
        name: "terms",
        component: TermsView,
      },
      {
        path: "/privacy",
        name: "privacy",
        component: PrivacyView,
      },
      {
        path: "/contact",
        name: "contact",
        component: ContactView,
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: ComingSoonView,
      },
      {
        path: "/publicity",
        name: "publicity",
        component: ComingSoonView,
      },
      {
        path: "/register",
        name: "register",
        component: RegisterView,
      },
    ],
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorView,
  },
];

export default routes;
