import axios from "axios";
import router from "@/router/index";

const apiKey = "9qaqH&9@Tr^SJzDGkeZQ71pZsHDKVw48aZVz&hZ6fUAb5XaSX!7vs4Xhy1dj";

const instance = axios.create({
  headers: {
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers["XApiKey"] = `${apiKey}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (401 === error.response.status) {
        localStorage.removeItem("token");
        localStorage.removeItem("portal.user");

        router.push("/login");
      }
      if (400 === error.response.status) {
        if (error.response.data && error.response.data.errors) {
          error.message = "";
          for (let value of Object.entries(error.response.data.errors)) {
            error.message += (error.message == "" ? "" : "<br />") + value;
          }
        }
      }
      if (404 === error.response.status) {
        error.message = "Not Found";
      }
    }

    return Promise.reject(error);
  }
);

export const HTTP = instance;
