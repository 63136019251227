<template>
  <div>
    <div class="container">
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <!-- <img
            alt="Midone Tailwind HTML Admin Template"
            class="h-48 lg:h-auto"
            src="@/assets/images/error-illustration.svg"
          /> -->
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium">404</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            {{ $t("error-page.title") }}
          </div>
          <div class="intro-x text-lg mt-3">
            {{ $t("error-page.desc") }}
          </div>
          <router-link
            to="/"
            class="intro-x btn py-3 px-4 text-white border-white dark:border-darkmode-400 dark:text-slate-200 mt-10"
          >
            {{ $t("error-page.btn-home") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
