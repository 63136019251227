import { HTTP } from "../utils/http";
import { config } from "../utils/config";
import { CategoryItem } from "@/models/Category";
import { PaginatedList } from "@/models/PaginatedList";
import { Post, PostItem } from "@/models/Post";
import { ContactUs } from "@/models/ContactUs";
import { PostCreate } from "@/models/PostCreate";

const categoriesStorageKey = "categories";

export const listCategories = async (): Promise<CategoryItem[]> => {
  const categories = localStorage.getItem(categoriesStorageKey);
  if (categories && categories.length > 0) {
    return JSON.parse(categories);
  }
  localStorage.removeItem(categoriesStorageKey);
  const res = await HTTP.get(`${config.apiEndpoints.portalUri}Categories`);
  res.data = res.data.map((x: CategoryItem) => new CategoryItem(x));
  localStorage.setItem(categoriesStorageKey, JSON.stringify(res.data));
  return res.data;
};

export const getCategory = async (slug: string): Promise<CategoryItem> => {
  const categories = await listCategories();
  const category = categories.find((x) => x.slug === slug);
  if (!category) {
    throw new Error("Category not found");
  }
  return category;
};

export const postsListPageByCategory = async (
  categoryHid: string,
  page: number
): Promise<PaginatedList<PostItem>> => {
  const res = await HTTP.get(
    `${config.apiEndpoints.portalUri}Categories/${categoryHid}/Posts?page=${page}`
  );
  return res.data;
};

export const postsSearchPage = async (
  search: string,
  page: number
): Promise<PaginatedList<PostItem>> => {
  const res = await HTTP.get(
    `${config.apiEndpoints.portalUri}Posts?search=${search}&page=${page}`
  );
  return res.data;
};

export const getPost = async (slug: string): Promise<Post> => {
  const res = await HTTP.get(`${config.apiEndpoints.portalUri}Posts/${slug}`);
  return res.data;
};

export const saveContactUs = async (model: ContactUs): Promise<boolean> => {
  const res = await HTTP.post(
    `${config.apiEndpoints.portalUri}ContactUs/`,
    model
  );
  return res.data;
};

export const savePost = async (model: PostCreate): Promise<boolean> => {
  const res = await HTTP.post(`${config.apiEndpoints.portalUri}Posts/`, model);
  return res.data;
};
