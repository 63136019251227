<template>
  <section class="search">
    <MenuNav color="gold" />
    <div class="content">
      <img src="../assets/images/logo.png" alt="micolist" />
      <h1>{{ $t("coming-soon.title") }}</h1>
      <p class="desc">{{ $t("coming-soon.desc") }}</p>
    </div>
  </section>
  <the-footer></the-footer>
</template>

<style lang="scss" scoped>
.search {
  background-color: var(--color-pink);
  height: 70vh;
  display: flex;
  justify-content: center;
  .content {
    width: 1300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    img {
      max-width: 261px;
    }
    h1 {
      font-size: 10rem;
      font-weight: 400;
      margin: 12rem auto 4rem auto;
      line-height: 15rem;
    }
    .desc {
      font-size: 4rem;
      font-weight: 400;
      margin: 0;
      line-height: 5rem;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1100px) {
  .search {
    height: 90vh;
    .content {
      width: 90%;
      h1 {
        font-size: 6rem;
        font-weight: 400;
        margin: 4rem auto 1rem auto;
        line-height: 8rem;
      }
      .desc {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
    }
  }
}
</style>
