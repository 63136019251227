export class CategoryItem {
  public hid: string;
  public name: string;
  public icon: string;
  public slug: string;

  public constructor({ hid, name, icon, slug }) {
    this.hid = hid;
    this.name = name;
    this.icon = icon;
    this.slug = slug;
  }
}
