<template>
  <section>
    <div class="content">
      <h1>PRIVACY POLICY</h1>
      <h6>Last Updated: November 10, 2023</h6>
      <h2>INTRODUCTION</h2>
      <p>
        Micolist [<a href="https://micolist.com">https://micolist.com</a>]
        ("our" "we" or "us") respect the privacy and are committed to protect
        the personal information of our Users ("user," "you," your" or "yours")
        when you visit or use Micolist [<a href="https://micolist.com"
          >https://micolist.com</a
        >] (the "App"). We provide our App and Services (hereinafter
        collectively, either "App" or "Services") to you as under the following
        privacy policy (the "Policy").
      </p>
      <h2>PERSONAL INFORMATION WE COLLECT</h2>
      <p>
        In order to keep the App functional, we must process information about
        you to operate, improve, modify, customize, support and market our
        services. If you do not agree with this, then you can update/delete your
        personal information: read here [<a href="https://micolist.com/profile"
          >https://micolist.com/profile</a
        >].
      </p>
      <p>
        The type of information that we collect depends on how you use our App
        and Services. We require certain information to deliver to you, our
        services, and without this, we will not be able to provide such services
        to you. Our Services have optional features, which, if used by you,
        require us to collect additional information to provide such features.
        You will be notified of such collection, as appropriate. If you choose
        not to provide the information needed to use a feature, you will be
        unable to use the feature. Please carefully read the following to
        understand what information we may collect:
      </p>
      <ul>
        <li>
          When you use the App or register to the App, we may ask you to provide
          certain information, including EMAIL, NAME, SURNAME OR PASSWORD. We
          will also collect or otherwise ask you to provide additional
          information that you provide us, such as your profile details,
          pictures and/or any other texts, images, videos or information that
          you may upload on the App and/or provide to the App.
        </li>
        <li>
          We also collect information while you access, browse, view or
          otherwise use the App. In other words, when you access the App we are
          aware of your usage of the App, and gather, collect and record the
          information relating to such usage, including geo-location
          information, IP address, device and connection information, browser
          information and web-log information, phone book and contacts, and all
          communications recorded by Users through the App. We use that
          information to enhance user experience, personalize your browsing
          experience as well as monitor the App for preventing fraud and
          inappropriate content or behavior. We collect texts, information,
          pictures, videos and/or any data that you may upload on the app. We
          also collect supplemental information obtained from third parties such
          as demographic and navigation data, if applicable. We combine your
          personal information with information we collect automatically or
          obtain from other companies and use it to improve and personalize our
          services, content and advertising and/or to prevent fraud.
        </li>
        <li></li>
      </ul>
      <p>
        All of the preceding is collected, as permitted by applicable laws
        relevant to your jurisdiction.
      </p>
      <h2>HOW DO WE COLLECT PERSONAL INFORMATION?</h2>
      <p>
        INFORMATION PROVIDED BY YOU. You directly provide us with most of the
        information we collect. You do this by filling out the registration
        details on the App, linking accounts of other social networks to your
        Micolist [<a href="https://micolist.com">https://micolist.com</a>]
        account, completing application forms, as well as by posting and sharing
        additional information voluntarily.
      </p>
      <p>
        INFORMATION WE COLLECT WHEN YOU USE THE APP. We collect technical
        information indirectly and automatically through our systems. This
        information includes logging your operating system, software
        configuration, Internet Protocol (IP) address and use of cookies
        (cookies are small files sent from us to your computer and sometimes
        back). Cookies ultimately help us improve your navigation and ease of
        use of our App.
      </p>
      <p>
        Should you require additional information about how we may use Cookies
        to improve the services we provide, please visit our COOKIE POLICY.
      </p>
      <p>
        INFORMATION WE COLLECT FROM OTHER SOURCES. We may receive information
        from third party vendors and/or other commercially available sources,
        such as:
      </p>
      <ul>
        <li>
          Third party vendors who provide us data to supplement the information
          we collect about you, in accordance with applicable laws. For example,
          we receive information from advertising and marketing partners in
          order to manage and measure our campaigns as well as from enrichment
          service providers to enhance, modify and personalize your experience
          of the App.
        </li>
        <li>
          For more information, please see the following sections of this
          Policy: ANALYTICS, COOKIES and MARKETING AND BEHAVIORAL REMARKETING
        </li>
      </ul>
      <h2>HOW WE USE COLLECTED PERSONAL INFORMATION?</h2>
      <p>
        All processing of your personal information will always be justified by
        a "lawful ground" for processing, where relevant under applicable laws.
        In the majority of cases, processing will be justified on the basis
        that:
      </p>
      <ul>
        <li>
          you, or in the case you are below the age of 16 or the age of legal
          adulthood if different in your country, your parent or guardian has
          provided consent for us to use your personal information for a
          specific purpose;
        </li>
        <li>
          the use of your personal information is necessary to perform a
          contract or take reasonable steps to enter into a contract with you
          (E.G. TO PROVIDE YOU WITH AGREEMENTS OR SERVICES WHICH YOU HAVE
          PURCHASED);
        </li>
        <li>
          the processing is necessary to comply with a relevant legal obligation
          or regulatory obligation that you and/or we have (e.g. fraud
          prevention); or
        </li>
        <li>
          the processing is necessary to support our legitimate interests as a
          business (e.g. to improve our services to you), subject to your
          interests and fundamental rights and provided it is conducted at all
          times in a way that is proportionate.
        </li>
      </ul>
      <p>We will use your personal information for the following purposes:</p>
      <ul>
        <li>To create user accounts.</li>
      </ul>
      <h2>HOW LONG DO WE RETAIN YOUR PERSONAL INFORMATION?</h2>
      <p>
        We retain personal information only for as long as is required to fulfil
        the purpose for which it was collected and unless otherwise stated for a
        period of: AS LONG AS THE USER HAS AN ACCOUNT WITH US. Please note that
        some circumstances, we will retain your personal information for longer
        periods of time. We will retain personal information for the following
        purposes:
      </p>
      <ul>
        <li>
          as long as it is necessary and relevant for our operations, e.g. as
          long as is necessary to provide services, etc.; and
        </li>
        <li>
          in relation to personal information to comply with applicable laws,
          prevent fraud, troubleshoot problems, assist with any investigation,
          enforce our App terms and take other actions as permitted by law.
        </li>
      </ul>
      <h2>CHILDREN UNDER THE LEGAL AGE OF ADULTHOOD</h2>
      <p>
        Our services are not directed to children under the age of thirteen, or
        any as such age relevant to Applicable Laws in your jurisdiction, and we
        do not knowingly collect personal information from children under the
        age of thirteen, or sixteen, through our Services. We encourage parents
        and legal guardians to help enforce our Privacy Notice by instructing
        children under the age of thirteen not to download or use our online
        services.
      </p>
      <p>
        Should we learn we have collected or received personal information from
        a child under 13 or any such age relevant to Applicable Laws in your
        jurisdiction, we shall delete that personal information immediately and
        if we shall have a reasonable doubt that the consent given is not
        truthful regarding the age of the user, we reserve ourselves the right
        to immediately suspend the access to the App giving notice of the
        reasons. If you believe we might have any information from or about a
        child under 13 or 16, or if you are a parent or guardian concerned that
        we may have collected information of your child, please contact us
        immediately at
        <a href="mailto:contact@micolist.com">CONTACT@MICOLIST.COM</a>.
      </p>
      <h2>SHARING PERSONAL INFORMATION WITH THIRD PARTIES</h2>
      <p>
        We do not sell or rent your personal information to third parties for
        their marketing purposes without your explicit consent.
      </p>
      <p>We share your personal information with third parties as under:</p>
      <ul>
        <li>
          We share personal information with service providers that provide us
          with services for the purpose of operating the App and opening and
          operating your account. Consistent with applicable legal requirements,
          we take commercially reasonable steps to require third parties to
          adequately safeguard your personal information and only process it in
          accordance with our instructions;
        </li>
        <li>
          We share personal information to law enforcement agencies, public
          authorities or other parties, if we believe we are required to do so
          by law, or that doing so is reasonably necessary to comply with our
          legal obligations;
        </li>
        <li>
          We share personal information with payment processors, fraud detection
          agencies and similar third parties for the purpose of facilitating
          payments done via the App and securing the App and protecting it
          against fraud or other liabilities;
        </li>
        <li>
          We also may share limited personal information with such social media
          platforms and marketing partners, such as Facebook, Twitter and
          Google, to promote our services. Such features and third party
          services collect certain information about you and may set a cookie to
          enable their functionality. Such third party features are governed by
          their privacy policies and under their terms and conditions.
        </li>
        <li>
          Personal information will also be disclosed if we go through a
          business transition such as a merger, sale, transfer of all or a
          portion of Micolist [<a href="https://micolist.com"
            >https://micolist.com</a
          >] 's assets, acquisition, bankruptcy or similar event. In the event
          that we sell any business or assets, we will disclose your data to the
          prospective buyer. If we or substantially all of our assets are
          acquired by a third party, information held by us about our users will
          be one of the transferred assets.
        </li>
      </ul>
      <p>
        Please note, when you publish your information and/or share your data
        with other Users, we try to maintain the standards of all users, but we
        do not take responsibility over the actions of other users.
      </p>
      <h2>WHERE WE STORE YOUR PERSONAL INFORMATION</h2>
      <p>
        We may store your data manually or electronically. Any data stored
        electronically will be stored in secure servers, and any data stored
        manually will be stored in our premises within the UNITED STATES.
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we will do our best to protect your personal
        data, we cannot guarantee the security of data transmitted to us and any
        transmission is at your own risk.
      </p>
      <h2>COOKIES</h2>
      <p>
        We use cookies and similar technologies (such as web beacons, pixels,
        tags, and scripts) to improve and personalize your experience, provide
        our services, analyze performance and for marketing purposes.
      </p>
      <p>
        Some of the services we use such as GOOGLE ANALYTICS [<a
          href="https://policies.google.com/privacy"
          target="_blank"
          >https://policies.google.com/privacy</a
        >] set cookies to allow us to gather anonymous data about your usage of
        our website.
      </p>
      <p>
        A cookie is a text file sent by a web server to a web browser and stored
        in the browser. The file is then sent back to the server each time the
        browser requests a page form the server. This enables the web server to
        identify and track the web browser.
      </p>
      <p>
        We may send a cookie which may be stored by your browser on your
        computer`s hard drive. The information we obtain from the cookie could
        be used to improve the website`s usability and for marketing purposes.
        We may also use that information to recognize your computer when you use
        our App and to personalize our App for you.
      </p>
      <p>
        Browsers usually will allow you to refuse cookies but this could have a
        negative impact on the usability of many websites.
      </p>
      <p>
        To learn more about how we and our third party service providers use
        cookies and your control over these Cookies, please see our Cookie
        Policy.
      </p>
      <h2>DO NOT TRACK</h2>
      <p>
        Do Not Track (DNT) is a privacy preference that users can set in some
        web browsers, allowing users to opt out of tracking by the web and
        online services. We do not honor browser requests not to be tracked
        online (known as "Do Not Track"), but our Cookie Policy describes how
        you can opt out of receiving cookies. To learn more about cookies,
        including how to view which cookies have been set and how to manage and
        delete them, please visit: www.allaboutcookies.org [<a
          href="https://allaboutcookies.org"
          target="_blank"
          >https://allaboutcookies.org</a
        >]. At this time, our App do not respond to Do Not Track beacons sent by
        browser plugins.
      </p>

      <h2>ANALYTICS:</h2>
      <p>
        We use GOOGLE ANALYTICS [<a
          href="https://policies.google.com/privacy"
          target="_blank"
          >https://policies.google.com/privacy</a
        >] to collect the following information when users access or interact
        with our App: This information may include, among other information, the
        browser and operating system you are using, the URL or advertisement
        that referred you to our Website, the search terms you entered into a
        search engine that led you to our Website, areas within our Website that
        you visited, and other information commonly shared when browsers
        communicate with websites. This information may also include x/y
        coordinates of mouse events, unique ID tracking cookie, Internet
        Protocol ("IP") address (anonymized for residents of the European
        Economic Area), unique device identifier ("Device Identifier") for any
        device (computer, mobile phone, tablet, etc.). A Device Identifier is a
        number that is automatically assigned or connected to the device you use
        to access the Service, and our servers identify your device by its
        Device Identifier. Some mobile service providers may also provide us or
        our third party service providers with information regarding the
        physical location of the device used to access the Service.
      </p>
      <p>
        We use the information from GOOGLE ANALYTICS [<a
          target="_blank"
          href="https://policies.google.com/privacy"
          >https://policies.google.com/privacy</a
        >] in order to assemble statistics regarding usage of our App as well as
        to help us understand how users engage with our App on an individual and
        aggregate basis in order to improve it; to help with customer service
        needs (such as troubleshooting issues that users report to us); and for
        research and analytical purposes such as service performance; customer
        behavior and common navigation through the App.
      </p>
      <p>
        We do not share our App traffic data with any third party for any
        purpose unless required for legal reasons such as meeting applicable
        laws, regulations, legal processes, or enforceable governmental requests
        and/or unless you have given an explicit consent to allow us to do so;
        enforcing applicable terms of service; detecting, preventing, or
        otherwise addressing fraud, security, or technical issues; protecting
        against harm to the rights, property or safety of our Company, our
        Customers, or the public as required or permitted by law.
      </p>
      <p>
        If you'd like to opt-out or If you'd like to obtain a copy of your data,
        make a correction, or have it erased, please contact us in any way
        convenient for you in the CONTACT US section.
      </p>
      <h2>DISCLAIMERS:</h2>
      <h4>ADS AND EXTERNAL LINKS</h4>
      <p>
        Please note that the App may contains links to third party Apps and
        advertisements, and if you link to a third party App from the App, any
        data you provide to that App and any use of that data by the third party
        are not under the control of Micolist [<a
          href="https://micolist.com"
          target="_blank"
          >https://micolist.com</a
        >] and are not subject to this Policy. You should consult the privacy
        policies of each App you visit. This Policy applies solely to personal
        information collected by our App. If you upload content, including
        personal information, to a social network and then tag the App, your
        submission will be subject to that social network's terms of use and
        privacy policy, even where you post on an official Micolist [<a
          href="https://micolist.com"
          target="_blank"
          >https://micolist.com</a
        >] page on the social network. We do not have control over these terms
        of use and privacy policies and have not reviewed their adequacy. You
        should therefore review these before submitting any personal
        information.
      </p>
      <h4>INCORRECT CONTENTS</h4>
      <p>
        We take reasonable steps to ensure the accuracy of the information
        provide on the App and on the external link in this policy listed.
        However, the information is provided "as is" without any warranty of
        guarantee of any kind. Under no circumstance should you rely on the
        accuracy of information given on the App.
      </p>
      <p>
        We take no responsibility or liability for the accuracy, content,
        completeness, reliability or legality of the information and contents
        contained on this App.
      </p>
      <h4>SPECIFIC PROVISIONS FOR CALIFORNIA RESIDENTS</h4>
      <p>
        This section of the Policy applies to you, if you are a California
        resident.
      </p>
      <p>
        We have collected the following categories of personal information from
        Users:
      </p>
      <ul>
        <li>
          Information that you chose to upload or otherwise provided by you
          which may include: (i) Identifiers and personal information, such as
          name, postal addresses, online identifiers, email addresses, and
          location.
        </li>
        <li>
          Information we collect when you use the App, including (i) Identifiers
          and personal information, such as online identifiers, internet
          protocol (IP) addresses, access device and connection information such
          as browser type, version, and time zone setting and browser plug-in
          types and versions; (ii) commercial information, including products or
          services purchased, obtained, or considered, or other purchasing or
          consuming histories or tendencies; (iii) Internet or other electronic
          network activity information, including, but not limited to log-in and
          log-out time, the duration of sessions, the content uploaded and
          downloaded, viewed web-pages or specific content on web-pages,
          activity measures; (iv) Location information, based on your IP address
          and GPS, (iv) phonebook based on your contact lists; (v) pictures
          based on your camera.
        </li>
        <li>
          Inferences drawn from any of the information identified above to
          create a profile about you.
        </li>
        <li>
          We obtain personal information listed above from third party sources,
          as detailed in this Policy.
        </li>
      </ul>

      <p>
        We use the personal information that we collect or receive for the
        business purposes as described above under the Section titled "HOW DO WE
        USE THE INFORMATION COLLECTED?".
      </p>
      <p>
        We disclose personal information to third parties for business purposes
        as described above under the Section titled "Sharing Personal
        Information with Third Parties" in the Policy. In the preceding twelve
        (12) months, we have disclosed all the categories of personal
        information detailed above for business purposes.
      </p>
      <p>
        As previously mentioned in this Policy, we do not sell (as such term is
        defined in the CCPA) personal information and will not sell it without
        providing Users with a right to opt out.
      </p>
      <p>
        You are entitled to the following specific rights under the CCPA in
        relation to personal information related to you:
      </p>
      <ul>
        <li>
          You have a right to request that we will disclose certain information
          to you about our collection and use of personal information related to
          you over the past 12 months. After verifying your request, we will
          disclose to you: (i) the categories of personal information that we
          collect about you; (ii) the categories of sources from which the
          personal information is collected; (iii) the purposes for collecting,
          using, or selling that personal information; (iv) the categories of
          personal information that we disclosed for a business purpose or sold,
          and the categories of third parties to whom we disclosed or sold that
          particular category of personal information; (v) the specific pieces
          of personal information that we have collected about you; (vi) if we
          disclosed your personal information for a business purpose, the
          categories of personal information we have disclosed; (vii) if we sold
          your personal information, the categories of personal information we
          have sold.
        </li>
        <li>
          You have a right to request that we delete personal information
          related to you that we collected from you under certain circumstances
          and exceptions.
        </li>
        <li>
          You also have a right not to be discriminated against for exercising
          your rights under the CCPA.
        </li>
      </ul>
      <p>
        To make such requests, we kindly ask that you contact us at
        <a href="mailto:contact@micolist.com">CONTACT@MICOLIST.COM</a>.
      </p>
      <p>
        We will verify your request using the information associated with your
        account, including email address. Government identification may also be
        required.
      </p>
      <p>
        A request for access can be made by you only twice within a 12-months
        period. Any disclosures that we provide will only cover the 12-months
        period preceding receipt of your request. We do not charge a fee to
        process or respond to your verifiable User request unless it is
        excessive, repetitive, or manifestly unfounded. If we determine that the
        request warrants a fee, we will inform you of the reasons for such
        decision and provide you with a cost estimate before processing further
        your request.
      </p>
      <h4>UPDATING PERSONAL INFORMATION</h4>
      <p>
        We take steps to ensure that the personal information we collect is
        accurate and up to date, and we provide you with the opportunity to
        update your information through your account profile settings. In the
        event that you believe your information is in any way incorrect or
        inaccurate, please let us know immediately. We will make sure we
        investigate the matter and correct any inaccuracies as quickly as
        possible where necessary or give you ways to update it quickly or to
        delete it - unless we have to keep that information for legitimate
        business or legal purposes. When updating your personal information, we
        will ask you to verify your identity before we can act on your request.
        If for any reason you have a problem with deleting your personal
        information, please contact us at
        <a href="mailto:contact@micolist.com">CONTACT@MICOLIST.COM</a> and we
        will make reasonable efforts to delete any such information pursuant to
        any applicable privacy laws.
      </p>
      <p>
        You can review and change your personal information: here [<a
          target="_blank"
          href="https://micolist.com/profile"
          >https://micolist.com/profile</a
        >].
      </p>
      <h4>CONTACT US</h4>
      <p>
        To exercise any of your rights in connection with your personal
        information, we kindly ask that you please contact us at
        <a href="mailto:contact@micolist.com">CONTACT@MICOLIST.COM</a>.
      </p>
      <p>
        If you have any questions (or comments) concerning this Policy, please
        email our team at
        <a href="mailto:contact@micolist.com">CONTACT@MICOLIST.COM</a>, and we
        will make an effort to reply within a reasonable timeframe.
      </p>
      <h2>CONTACT US</h2>
      <p>
        For any questions or queries regarding this Privacy Policy, please feel
        free to contact us:
      </p>
      <p><a href="mailto:contact@micolist.com">CONTACT@MICOLIST.COM</a></p>
    </div>
  </section>
</template>
<style lang="scss" scoped>
section {
  background-color: var(--color-green);
  padding: 40px 0;
  .content {
    width: 90%;
    max-width: 1600px;
    margin: auto;
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2rem;
    }
    h6 {
      font-size: 2rem;
      font-weight: normal;
      font-style: italic;
    }
    a {
      color: var(--color-pink);
    }
  }
}
</style>
