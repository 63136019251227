<template>
  <div
    ref="fileUploadRef"
    v-file-upload-directive="{ props, emit }"
    class="dropzone"
  >
    <div class="dz-message">
      <span>{{ $t("register.form.image-call") }}</span>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, onMounted, defineProps } from "vue";
import { init } from "./index";

const vFileUploadDirective = {
  mounted(el, { value }) {
    init(el, value.props);
  },
};

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
  },
  refKey: {
    type: String,
    default: null,
  },
});

//const emit = defineEmits();

const fileUploadRef = ref();
const bindInstance = () => {
  if (props.refKey) {
    const bind = inject(`bind[${props.refKey}]`);
    if (bind) {
      bind(fileUploadRef.value);
    }
  }
};

onMounted(() => {
  bindInstance();
});
</script>
<style lang="scss" scoped>
.dropzone {
  width: 100%;
  border: 3px solid var(--color-black);
  padding: 1rem 2rem;
  font-size: 2rem;
  border-radius: 3rem;
  margin-bottom: 3rem;
  height: 25rem;
  position: relative;
}
</style>
