//const rootUri = "https://localhost:7012/api/";
const rootUri = "https://api.micolist.com/api/";

const configs = {
  apiEndpoints: {
    portalUri: rootUri + "portal/",
  },
};

const install = (app) => {
  app.config.globalProperties.$c = configs;
};

export { install as default, configs as config };
