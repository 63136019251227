import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(i18n);
app.use(VueAxios, axios);

app.use(
  VueGtag,
  {
    config: { id: "G-SRR8DFXM73" },
  },
  router
);
app.use(router);

router.isReady().then(() => {
  app.mount("#app");
});
