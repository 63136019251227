<template>
  <section class="register">
    <h2>{{ $t("register.title") }}</h2>
    <div class="container">
      <form>
        <div class="control">
          <label for="name">{{ $t("register.form.name") }}</label>
          <input
            name="name"
            type="text"
            :class="{ 'border-danger': validate.name.$error }"
            v-model="model.name"
          />
        </div>
        <div class="quarter">
          <div class="control">
            <label for="name">{{ $t("register.form.description") }}</label>
            <textarea
              name="description"
              v-model="model.description"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="control">
            <label for="image">{{ $t("register.form.image") }}</label>
            <Dropzone
              :options="{
                url: `${config.apiEndpoints.portalUri}UploadImage`,
                thumbnailWidth: 150,
                maxFilesize: 50,
                maxFiles: 1,
                headers: {
                  XApiKey: `ER6MrVpAhXSb2TfCXmyYee4McrZfLYGkQCP9PQud454WhkFP7HU6b81L7mFQ765GWVgCBPAwpst2WKRPMNshXD86Dt2b6f1`,
                },
                success: uploadSuccess,
                acceptedFiles: `.jpg,.png,.gif`,
              }"
              class="dropzone"
            >
              <div class="text-lg font-medium">
                {{ $t("posts.form.dropzone") }}
              </div>
            </Dropzone>
          </div>
        </div>

        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.email") }}</label>
            <input
              name="email"
              type="email"
              :class="{ 'border-danger': validate.email.$error }"
              v-model="model.email"
            />
          </div>
          <div class="control">
            <label for="name">{{ $t("register.form.phone") }}</label>
            <input
              name="phone"
              type="tel"
              :class="{ 'border-danger': validate.phone.$error }"
              v-model="model.phone"
            />
          </div>
        </div>
        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.instagram") }}</label>
            <input name="instagram" type="text" v-model="model.instagram" />
          </div>
          <div class="control">
            <label for="name">{{ $t("register.form.facebook") }}</label>
            <input name="facebook" type="text" v-model="model.facebook" />
          </div>
        </div>
        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.website") }}</label>
            <input name="website" type="text" v-model="model.website" />
          </div>
          <div class="control">
            <label for="name">{{ $t("register.form.whatsapp") }}</label>
            <input name="whatsapp" type="text" v-model="model.whatsapp" />
          </div>
        </div>
        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.category") }}</label>
            <select name="category" v-model="model.categoryHid">
              <option v-for="cat in categories" :key="cat.hid" :value="cat.hid">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.street") }}</label>
            <input
              name="street"
              type="text"
              ref="streetRef"
              v-model="model.street"
            />
          </div>
          <div class="control">
            <label for="name">{{ $t("register.form.street2") }}</label>
            <input name="street2" type="text" v-model="model.street2" />
          </div>
        </div>
        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.city") }}</label>
            <input name="city" type="text" v-model="model.city" />
          </div>
          <div class="control">
            <label for="name">{{ $t("register.form.state") }}</label>
            <input name="state" type="text" v-model="model.state" />
          </div>
        </div>
        <div class="half">
          <div class="control">
            <label for="name">{{ $t("register.form.zip") }}</label>
            <input name="zip" type="text" v-model="model.zipCode" />
          </div>
        </div>

        <button type="button" @click="save">
          {{ $t("contact.form.btn-send") }}
          <LoadingIcon
            icon="three-dots"
            color="EF3A5D"
            class="w-4 h-4 ml-2"
            v-if="isLoading"
          />
        </button>
      </form>
    </div>
  </section>
  <div v-if="showSuccessModal" class="modal">
    <div class="box">
      <h4>{{ $t("register.modal.title") }}</h4>
      <p>{{ $t("register.modal.message") }}</p>
      <button @click="hideSuccessModal">
        {{ $t("register.modal.btn-ok") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  toRefs,
  onMounted,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { config } from "@/utils/config";
import { required, minLength, maxLength, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { listCategories, savePost } from "@/services/portalService";
import Dropzone from "@/components/dropzone/MainView.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const mapsSrc =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDUfvqwjsi8rLEhkC_-ND80SlAfrlZkTf4&libraries=places";
const streetRef = ref(null);
const categories = ref([]);
const model = reactive({
  name: "",
  description: "",
  categoryHid: "",
  phone: "",
  instagram: "",
  facebook: "",
  website: "",
  email: "",
  whatsapp: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  zipCode: "",
  image: "",
  lat: "",
  lng: "",
});

const rules = {
  name: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(50),
  },
  email: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(500),
    email,
  },
  phone: {
    required,
    minLength: minLength(10),
    maxLength: maxLength(20),
  },
};
const isLoading = ref(false);
const showSuccessModal = ref(false);
const validate = useVuelidate(rules, toRefs(model));

const clearAddress = () => {
  model.street = model.street2 = model.city = model.state = model.zipCode = "";
  model.lat = model.lng = "";
};

const clearModel = () => {
  model.name =
    model.description =
    model.categoryHid =
    model.phone =
    model.instagram =
    model.facebook =
    model.website =
    model.email =
    model.whatsapp =
    model.image =
      "";
  clearAddress();
};

const uploadSuccess = (file, responseText, e) => {
  model.image = responseText.url;
};

const hideSuccessModal = () => {
  router.go();
  showSuccessModal.value = false;
};

const save = async () => {
  if (isLoading.value) return;
  validate.value.$touch();
  if (validate.value.$invalid) return;
  isLoading.value = true;

  savePost(model).then(
    (response) => {
      showSuccessModal.value = true;
      isLoading.value = false;
    },
    (err) => {
      console.log(err);
      alert(err);
      isLoading.value = false;
    }
  );
};

const fetchData = async () => {
  categories.value = await listCategories();
};

onMounted(() => {
  new Promise((resolve, reject) => {
    let script = document.querySelector(`script[src="${mapsSrc}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = mapsSrc;
      script.async = true;
      script.setAttribute("data-status", "loading");
      document.head.append(script);
    }

    if (script.getAttribute("data-status") === "loaded") {
      resolve();
    }

    function onScriptLoad() {
      resolve();
      script.setAttribute("data-status", "loaded");
    }

    function onScriptError() {
      reject();
      script.setAttribute("data-status", "error");
    }

    script.addEventListener("load", onScriptLoad);
    script.addEventListener("error", onScriptError);

    onUnmounted(() => {
      if (document.head.contains(script)) {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
        document.head.removeChild(script);
      }
    });
  }).then(() => {
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(streetRef.value, {
      types: ["address"],
      fields: ["address_components", "geometry"],
    });

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      clearAddress();
      let place = {
        address_components: [],
        ...autocomplete.getPlace(),
      };
      place.address_components.forEach((component) => {
        component.types.forEach((type) => {
          if (type == "street_number") {
            model.street = component.long_name + " " + model.street;
          }
          if (type == "route") {
            model.street = model.street + " " + component.long_name;
          }
          if (type == "locality") {
            model.city = component.long_name;
          }
          if (type == "administrative_area_level_1") {
            model.state = component.short_name;
          }
          if (type == "postal_code") {
            model.zipCode = component.long_name;
          }
        });
      });
      if (place.address_components.length > 0) {
        model.lat = autocomplete.getPlace().geometry.location.lat().toString();
        model.lng = autocomplete.getPlace().geometry.location.lng().toString();
      }
    });
  });
});

onBeforeMount(() => {
  fetchData();
});
</script>

<style lang="scss" scoped>
.register {
  background-color: var(--color-yellow);
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 6rem;
    color: var(--color-black);
    margin-bottom: 3rem;
    text-align: center;
  }
  .container {
    width: 80%;
    max-width: 1300px;
    display: flex;
    flex-flow: row;
    margin: 10rem auto;
    justify-content: space-between;
    form {
      width: 100%;
      background-color: var(--color-white);
      padding: 5rem;
      border-radius: 3rem;
      input,
      select {
        width: 100%;
        border: 3px solid var(--color-black);
        padding: 1rem 2rem;
        font-size: 2rem;
        border-radius: 3rem;
        margin-bottom: 3rem;
        &.border-danger {
          border-color: var(--color-red);
        }
      }
      label {
        font-size: 2rem;
        padding-left: 2rem;
        margin-bottom: 0.5rem;
        color: var(--color-black);
      }
      .half {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .control {
          width: 48%;
        }
      }
      .quarter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .control:first-child {
          width: 73%;
        }
        .control:last-child {
          width: 23%;
        }
      }
      button {
        width: 100%;
        border: 3px solid var(--color-black);
        padding: 1rem 2rem;
        font-size: 2rem;
        border-radius: 3rem;
        margin-bottom: 3rem;
        cursor: pointer;
        &:hover {
          border: 3px solid var(--color-green);
          color: var(--color-green);
        }
      }
      textarea {
        width: 100%;
        border: 3px solid var(--color-black);
        padding: 1rem 2rem;
        font-size: 2rem;
        border-radius: 3rem;
        margin-bottom: 3rem;
      }
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.9);
  z-index: 100;
  .box {
    background-color: var(--color-white);
    padding: 30px;
    border-radius: 30px;
    text-align: center;
    width: 40%;
    margin: 200px auto;
    h4 {
      font-size: 4rem;
      height: 7rem;
      margin: 4rem 0;
      color: var(--color-green);
    }
    p {
      font-size: 2.5rem;
      line-height: 4rem;
      color: var(--color-black);
    }
    button {
      width: 100px;
      border: 3px solid var(--color-black);
      padding: 1rem 2rem;
      font-size: 2rem;
      border-radius: 3rem;
      margin: 3rem auto;
      cursor: pointer;
      &:hover {
        border: 3px solid var(--color-green);
        color: var(--color-green);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .register {
    h2 {
      font-size: 5rem;
      line-height: 6rem;
      margin: 3rem;
    }
    .container {
      width: 90%;
      margin: 5rem auto;
      form {
        padding: 3rem 2rem;
        .half {
          display: flex;
          flex-direction: column;
          .control {
            width: 100%;
          }
        }
        .quarter {
          display: flex;
          flex-direction: column;
          .control:first-child {
            width: 100%;
          }
          .control:last-child {
            width: 100%;
          }
        }
      }
    }
  }
  .modal {
    .box {
      width: 90%;
      margin: 100px auto;
      h4 {
        margin: 4rem 0 2rem 0;
      }
      p {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .contact {
    .container {
      width: 90%;
      .form {
        padding: 4rem 2rem;
      }
    }
  }
}
</style>
